import * as React from 'react'
import styled from 'styled-components'
import SectionTitle from '../../atoms/SectionTitle'
import { IThemeDetail } from '../../../styles/theme'
import InnerSectionTitle from '../../atoms/InnerSectionTitle'
import InnerSectionText from '../../atoms/InnerSectionText'
import ServiceCassette from '../../atoms/ServiceCassette'
import { mediaWidth } from '../../../styles/layout'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'

const Wrapper = styled.div`
  display: grid;
  row-gap: 15px;
`

const SectionWrapper = styled.div`
  display: grid;
  row-gap: 30px;
`

const Inner = styled.div`
  display: grid;
  row-gap: 10px;
`

const CassetteWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
`

const VisualWrapper = styled.div`
  margin-top: 20px;
`

const FadeInWrapper = customFadeInWrapper({ delay: 500 })

interface IProps {
  theme: IThemeDetail
}

const IndexServiceSection: React.FC<IProps> = ({ theme }) => (
  <>
    <Wrapper>
      <SectionTitle color={ theme.color } index={ '03' }>SERVICE</SectionTitle>
      <FadeInWrapper>
        <SectionWrapper>
          <Inner>
            <InnerSectionTitle color={ theme.color }
                               hrTitle="STANCE 01">「できない」の前に<br />「できるためにはどうするか？」を探求</InnerSectionTitle>
            <InnerSectionText color={ theme.color }>
              さまざまな課題に対して、デザインの枠を超えたコンサルティングが可能です。<br />
              困難といわれる課題に対しても、「できるためにはどうするか?」をメンバー一同で考え、実行していきます
            </InnerSectionText>
          </Inner>
          <Inner>
            <InnerSectionTitle color={ theme.color } hrTitle="STANCE 02">本当に必要なものだけを提案するサービススタイル</InnerSectionTitle>
            <InnerSectionText color={ theme.color }>
              当社では企画段階からクリエイティブに携わり、ローンチまでサポートしていく体制をとっています。<br />
              課題やビジョンに対して、何が必要かを考えるため、デザインもあくまで手段のひとつ。<br />
              例えばWEBサイトが必要であればサイトの企画からローンチまで。<br />
              ほかにも、イベントブース展開や、動画作成、DMやチラシまで、さまざまなクリエイティブを手がけることができ、<br />
              それらを用いて最善の提案を行うことが可能です。
            </InnerSectionText>
          </Inner>
        </SectionWrapper>
        <VisualWrapper>
          <Inner>
            <CassetteWrapper>
              <ServiceCassette index="01" title="コンテンツ作成"
                               color={ theme.color }>オンライン・オフライン問わず、多彩な実績を持つ当社。単純な広告のデザインのみならず、WEBサイトの制作、自社HPの新規制作・リニューアル、動画コンテンツなど、ありとあらゆるコンテンツを作成することが可能です。</ServiceCassette>
              <ServiceCassette index="02" title="コンサルティング"
                               color={ theme.color }>明確に指定されたコンテンツのクリエイティブはもちろん、課題を伺った上で最適なコンテンツを企画・作成いたします。「なんとなくこんなことをしたい」といった、まだ明確に言語化できていないことも、方向性をしっかり共有し、コンテンツへと落とし込みます。</ServiceCassette>
              <ServiceCassette index="03" title="クリエイティブネットワーク"
                               color={ theme.color }>人物インタビュー、原稿の執筆、ハイセンスな写真撮影など、当社は独自のネットワークを駆使し、最適なクリエイターをアテンドします。デザインのみならず、クリエイティブ関連でお困りごとなどあれば、まずはご相談ください。</ServiceCassette>
            </CassetteWrapper>
          </Inner>
        </VisualWrapper>
      </FadeInWrapper>
    </Wrapper>
  </>
)

export default IndexServiceSection