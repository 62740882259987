import * as React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { mediaWidth } from '../../../styles/layout'
import { IContentfulWork } from '../../../common'
import { IThemeDetail } from '../../../styles/theme'

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
`

const Item = styled.li`
  width: 100%;
`

const ItemLink = styled(Link)`
  display: block;
  transition: opacity .5s;
  text-decoration: none;
  &:hover {
    opacity: .5;
  }
`

const WorkImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
`

const WorkImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

interface IWorkTitle {
  color: string
}

const WorkTitle = styled.p`
  color: ${ ({ color }: IWorkTitle) => color };
  margin-top: 10px;
`

interface IProps {
  works: IContentfulWork
  theme: IThemeDetail
}

const WorkThumbnail: React.FC<IProps> = ({ works, theme }) => (
  <Wrapper>
    { works.edges.map((work, i) => {
      const srcOriginal = work.node.main.sizes.src.replace(/\?.*$/, '')
      return (
        <Item key={ i }>
          <ItemLink to={ `/work/${ work.node.contentful_id }.html` }>
            <WorkImageWrapper>
              <WorkImage src={ srcOriginal } />
            </WorkImageWrapper>
            <WorkTitle color={ theme.color }>{ work.node.name }</WorkTitle>
          </ItemLink>
        </Item>
      )
    }) }
  </Wrapper>
)

export default WorkThumbnail
