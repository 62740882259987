import * as React from 'react'
import IndexLayout from '../components/templates/IndexLayout'
import { graphql } from 'gatsby'
import { IContentfulWork } from '../common'

interface IProps {
  data: {
    allContentfulWork: IContentfulWork
  }
}

const IndexPage: React.FC<IProps> = ({ data: { allContentfulWork  } }) => (
  <IndexLayout works={ allContentfulWork } />
)

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulWork(limit: 4) {
      edges {
        node {
          name
          contentful_id
          main {
            sizes {
              src
            }
          }
          subset {
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
