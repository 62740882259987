import * as React from 'react'
import styled from 'styled-components'
import SectionTitle from '../../atoms/SectionTitle'
import { IThemeDetail } from '../../../styles/theme'
import InnerSectionTitle from '../../atoms/InnerSectionTitle'
import InnerSectionText from '../../atoms/InnerSectionText'
import { fontSize } from '../../../styles/font'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import { mediaWidth } from '../../../styles/layout'

const Wrapper = styled.div`
  display: grid;
  row-gap: 30px;
  margin-top: 30px;
`

const CompanyLtdName = styled.p`
  font-size: ${ fontSize.px12 };
  font-weight: bold;
`

const IframeWrapper = styled.div`
  width: 100%;
  height: 300px;
`

const AccsessInnerSectionText = styled(InnerSectionText)`
  font-size: ${ fontSize.px14 };
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px12 };
  }
`

const mapIFrame = {
  __html: `<iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.7666412636313!2d139.6952884508125!3d35.682747580096645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cce94e0755d%3A0xee610db7e6e0117!2z44CSMTUxLTAwNTMg5p2x5Lqs6YO95riL6LC35Yy65Luj44CF5pyo77yS5LiB55uu77yT77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1614418990286!5m2!1sja!2sjp"
    width="100%"
    height="100%"
    frameBorder="0"
    allowFullScreen=""
    style="border:0;width:100%;filter:grayscale(100%);-webkit-filter:grayscale(100%)"
  ></iframe>`,
}

const FadeInWrapper = customFadeInWrapper({ delay: 500 })

interface IProps {
  theme: IThemeDetail
}

const IndexAccessSection: React.FC<IProps> = ({ theme }) => (
  <>
    <SectionTitle color={ theme.color } index={ '04' }>COMPANY</SectionTitle>
    <FadeInWrapper>
      <Wrapper>
        <div>
          <InnerSectionTitle color={ theme.color }>株式会社 FULL DESIGN</InnerSectionTitle>
          <CompanyLtdName>FULL DESIGN Co., Ltd.</CompanyLtdName>
        </div>
        <AccsessInnerSectionText color={ theme.color }>
          所在地:〒151-0053 東京都渋谷区代々木2-33-1 206<br />
          代表取締役社長:古屋 裕啓<br />
          お問い合わせ : info@full-design.jp
        </AccsessInnerSectionText>
        <IframeWrapper dangerouslySetInnerHTML={ mapIFrame } />
      </Wrapper>
    </FadeInWrapper>
  </>
)

export default IndexAccessSection