import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'

interface ITitle {
  color: string
}

const Title = styled.h2`
  color: ${ ({ color }: ITitle) => color };
  font-size: ${ fontSize.px50 };
  font-weight: bold;
  line-height: 1;
  margin-top: 10px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px36 };
  }
`

const SectionIndex = styled.span`
  color: ${ ({ color }: ITitle) => color };
  font-size: ${ fontSize.px20 };
  font-family: FB Benton Sans Bold,Roboto,ヒラギノ角ゴ ProN W3,Hiragino Kaku Gothic ProN,HiraKakuProN-W3,メイリオ,Meiryo,ＭＳ\\ Ｐゴシック,sans-serif;
  font-weight: bold;
  line-height: 1;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px14 };
  }
`

interface IProps extends ITitle {
  index: string
}

const SectionTitle: React.FC<IProps> = ({ children, color, index }) => (
  <div>
    <SectionIndex color={ color }>{ index }</SectionIndex>
    <Title color={ color }>{ children }</Title>
  </div>
)

export default SectionTitle
