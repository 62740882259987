import * as React from 'react'
import styled from 'styled-components'
import SectionTitle from '../../atoms/SectionTitle'
import { IThemeDetail } from '../../../styles/theme'
import InnerSectionTitle from '../../atoms/InnerSectionTitle'
import InnerSectionText from '../../atoms/InnerSectionText'
import { mediaWidth } from '../../../styles/layout'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'

const Wrapper = styled.div`
  display: grid;
  row-gap: 15px;
`

const IdentityImage = styled.img`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding-left: 30px;
  margin: 50px auto 0;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding-left: 15px;
    width: 100%;
  }
`

const FadeInWrapper = customFadeInWrapper({ delay: 500 })

const SectionInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
`

interface IProps {
  theme: IThemeDetail
}

const IndexIdentitySection: React.FC<IProps> = ({ theme }) => (
  <>
    <Wrapper>
      <SectionTitle color={ theme.color } index={ '02' }>CORPORATE IDENTITY</SectionTitle>
      <FadeInWrapper>
        <SectionInner>
          <InnerSectionTitle color={ theme.color }>企業の成長フェーズに<br />あわせたクリエイティブの提案</InnerSectionTitle>
          <InnerSectionText color={ theme.color }>
            街中でよく見かける広告物はもちろん、<br />
            ポスターやチラシ、パッケージ、店舗装飾にいたるまで、<br />
            多種多様なデザインを手がける一方で、大手求人広告会社とともに、<br />
            企業の求人広告・ブランディングなどのマーケティング領域に対し、<br />
            「デザイン」という手法で課題解決を行ってきました。<br />
            さまざまな広告に対しての知見はもちろん、<br />
            求人広告に対する知見も持ち合わせており、課題の本質をクリアにし、<br />
            「デザイン」という手段を用いて課題解決を行うクリエイティブ集団です。<br />
          </InnerSectionText>
          <IdentityImage src="/__contentful__/idnetity.png" />
        </SectionInner>
      </FadeInWrapper>
    </Wrapper>
  </>
)

export default IndexIdentitySection