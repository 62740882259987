import * as React from 'react'
import { GlobalStyles } from '../../../styles/global'
import styled from 'styled-components'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import { IndexMainSection } from '../../organisms/IndexMainSection'
import { IThemeDetail, themes } from '../../../styles/theme'
import IndexWorkSection from '../../organisms/IndexWorkSection'
import { mediaWidth, pcSpace, spSpace } from '../../../styles/layout'
import IndexIdentitySection from '../../organisms/IndexIdentitySection'
import IndexServiceSection from '../../organisms/IndexServiceSection'
import IndexAccessSection from '../../organisms/IndexAccessSection'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import { IContentfulWork } from '../../../common'

const Contents = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
`

interface IWrapper {
  theme: IThemeDetail
}

const Wrapper = styled.div`
  background-color: ${ ({ theme }: IWrapper) => theme.background };
  display: flex;
  justify-content: center;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: 100px ${ pcSpace.outline.side }px;
  max-width: 1024px;
  width: 100%;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 50px ${ spSpace.outline.side }px;
  }
`

const MainInner = styled(Inner)`
  padding: 0 ${ pcSpace.outline.side }px;
  margin-bottom: 100px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 0 ${ spSpace.outline.side }px;
    margin-bottom: 50px;
  }
`

const FadeInWrapper = customFadeInWrapper({})

interface IProps {
  works: IContentfulWork
}

const IndexLayout: React.FC<IProps> = ({ works }) => (
  <>
    <GlobalStyles />
    <Header />
    <Contents>
      <Wrapper theme={ themes.white }>
        <MainInner id="top">
          <IndexMainSection />
        </MainInner>
      </Wrapper>
      <Wrapper theme={ themes.dark } id="work">
        <Inner>
          <FadeInWrapper>
            <IndexWorkSection theme={ themes.dark } works={ works } />
          </FadeInWrapper>
        </Inner>
      </Wrapper>
      <Wrapper theme={ themes.white } id="identity">
        <Inner>
          <FadeInWrapper>
            <IndexIdentitySection theme={ themes.white } />
          </FadeInWrapper>
        </Inner>
      </Wrapper>
      <Wrapper theme={ themes.gray } id="service">
        <Inner>
          <FadeInWrapper>
            <IndexServiceSection theme={ themes.gray } />
          </FadeInWrapper>
        </Inner>
      </Wrapper>
      <Wrapper theme={ themes.white } id="company">
        <Inner>
          <FadeInWrapper>
            <IndexAccessSection theme={ themes.white } />
          </FadeInWrapper>
        </Inner>
      </Wrapper>
    </Contents>
    <Footer />
  </>
)

export default IndexLayout
