import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import FadeInText from '../../atoms/FadeInText'
import * as React from 'react'
import SignLogo from '../../atoms/SignLogo'

const VisualWrapper = styled.div`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 600px;
  padding: 100px 0;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 50px 0;
  }
`

const MainVisual = styled.img`
  width: 100%;
`

const MainTextWrapper = styled.div`
  padding: 0 20px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 0;
  }
`

const MainTextInner = styled.div`
    &:not(:first-child) {
      margin-top: 50px;
      @media screen and (max-width: ${ mediaWidth.sp }px) {
        margin-top: 30px;
      }
    }
    & br {
      @media screen and (min-width: ${ mediaWidth.sp }px) {
        display: none;
      }
    }
`

const InnerBracketMargin = styled.div`
  display: inline-block;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    margin-left: -10px;
  }
`

const BracketMargin = styled.div`
  margin-left: -15px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    margin-left: -10px;
  }
`

export const IndexMainSection = () => (
  <>
    <VisualWrapper>
      <MainVisual src="/index/main-visual.png" alt="" />
    </VisualWrapper>
    <MainTextWrapper>
      <MainTextInner>
        <FadeInText>これでいいや。</FadeInText><br />
        <div>
          <FadeInText>いつだって</FadeInText><br />
          <InnerBracketMargin>
            <FadeInText>「最高」を諦めるのは</FadeInText>
          </InnerBracketMargin><br />
          <FadeInText>自分の意思だ。</FadeInText>
        </div>
      </MainTextInner>
      <MainTextInner>
        <FadeInText>これじゃなきゃダメだ。</FadeInText>
        <div>
          <FadeInText>いつだって「最高」は</FadeInText><br />
          <FadeInText>限界の先にしかない。</FadeInText>
        </div>
      </MainTextInner>
      <MainTextInner>
        <FadeInText>楽しくなんてない。</FadeInText><br />
        <FadeInText>ラクなはずがない。</FadeInText>
      </MainTextInner>
      <MainTextInner>
        <FadeInText>苦悩し、苦悶し、苦心し、</FadeInText><br />
        <div>
          <FadeInText>そうしてたどり着いた先にしか</FadeInText><br />
        </div>
        <BracketMargin>
          <FadeInText>「最高」は存在しないことを</FadeInText>
        </BracketMargin><br />
        <FadeInText>私たちは知っている。</FadeInText>
      </MainTextInner>
      <MainTextInner>
        <FadeInText>もういい。もうここまででいい。</FadeInText>
        <FadeInText>そんな声が聞こえても、</FadeInText><br />
        <FadeInText>私たちは最高を追い求める</FadeInText>
      </MainTextInner>
      <MainTextInner>
        <FadeInText>昨日の最高を、</FadeInText><br />
        <FadeInText>今日の当たり前に。</FadeInText>
        <FadeInText>さぁ、昨日の最高を</FadeInText><br />
        <FadeInText>超えていこう。</FadeInText>
      </MainTextInner>
      <SignLogo />
    </MainTextWrapper>
  </>
)