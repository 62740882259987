import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'

const HrWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    width: 100%;
  }
  &:after {
    background-color: #000;
    content: '';
    display: block;
    flex-grow: 1;
    margin-left: 20px;
    height: 1px;
  }
`

const HrTitle = styled.p`
  font-weight: bold;
  font-size: ${ fontSize.px16 };
`

interface ITitle {
  color: string
}

const Title = styled.h3`
  color: ${ ({ color }: ITitle) => color };
  font-size: ${ fontSize.px20 };
  font-weight: bold;
  line-height: 1.5;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px16 };
  }
`

interface IProps extends ITitle {
  hrTitle?: string
}

const InnerSectionTitle: React.FC<IProps> = ({ children, color, hrTitle }) => (
  <>
    { hrTitle && (
      <HrWrapper>
        <HrTitle>{ hrTitle }</HrTitle>
      </HrWrapper>
    ) }
    <Title color={ color }>{ children }</Title>
  </>
)

export default InnerSectionTitle
