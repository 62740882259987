import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'
import intersectionAnimation, { IAnimationRefProps } from '../../hoc/intersectionAnimation'

const MainText = styled.p`
  color: #ccc;
  display: inline-block;
  font-size: ${ fontSize.px36 };
  font-family: FB PoynterSerif RE Regular,A1 Mincho,ヒラギノ明朝 ProN W3,HiraMinProN-W3,ＭＳ\\ Ｐ明朝,serif;
  line-height: 1.75;
  transition: 1s;
  transition-property: color;
  &.anim-enter,
  &.anim-enter-done {
    color: #333;
  }
  &.anim-exit,
  &.anim-exit-done {
    color: #ccc;
  }
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px20 };
  }
`

interface IProps extends IAnimationRefProps{
}

const FadeInText: React.FC<IProps> = ({ children, animationRef }) => (
  <MainText ref={ animationRef }>
    { children }
  </MainText>
)

export default intersectionAnimation({
  rootMargin: '9999px 0px -20%',
  timeout: 500,
})(FadeInText)
