import * as React from 'react'
import styled from 'styled-components'
import { fontSize } from '../../../styles/font'
import { mediaWidth } from '../../../styles/layout'

const Item = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 77px 1fr;
  row-gap: 15px;
  width: 100%;
`

interface IServiceColor {
  color: string
}

const ServiceIndex = styled.p`
  color: ${ ({ color }: IServiceColor) => color };
  font-size: ${ fontSize.px16 };
  font-weight: bold;
`

const ServiceTitle = styled.p`
  border: 1px solid #000;
  color: ${ ({ color }: IServiceColor) => color };
  margin-top: 10px;
  font-size: ${ fontSize.px16 };
  font-weight: bold;
  line-height: 1;
  padding: 15px 0;
  text-align: center;
`

const ServiceText = styled.p`
  color: ${ ({ color }: IServiceColor) => color };
  font-size: ${ fontSize.px16 };
  line-height: 1.5;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px14 };
  }
`

export interface IProps extends IServiceColor{
  index: string
  title: string
}

const ServiceCassette: React.FC<IProps> = ({ index, title, children, color }) => (
  <Item>
    <div>
      <ServiceIndex color={ color }>{ index }</ServiceIndex>
      <ServiceTitle color={ color }>{ title }</ServiceTitle>
    </div>
    <ServiceText color={ color }>{ children }</ServiceText>
  </Item>
)

export default ServiceCassette
