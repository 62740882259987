import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'

interface ITitle {
  color: string
}

const InnerSectionText = styled.p`
  color: ${ ({ color }: ITitle) => color };
  font-size: ${ fontSize.px16 };
  line-height: 2;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px14 };
  }
`

export default InnerSectionText
