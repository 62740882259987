import * as React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { mediaWidth } from '../../../styles/layout'
import SectionTitle from '../../atoms/SectionTitle'
import { IThemeDetail } from '../../../styles/theme'
import WorkThumbnail from '../../molecules/WorkThumbnail'
import { fontSize } from '../../../styles/font'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import { IContentfulWork } from '../../../common'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 40px;
  row-gap: 40px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    grid-template-columns: 1fr;
  }
`

interface IAllWorkLinkProps {
  color: string
}

const AllWorkLink = styled(Link)`
  display: block;
  font-size: ${ fontSize.px16 };
  font-family: FB Benton Sans Bold,Roboto,ヒラギノ角ゴ ProN W3,Hiragino Kaku Gothic ProN,HiraKakuProN-W3,メイリオ,Meiryo,ＭＳ\\ Ｐゴシック,sans-serif;
  color: ${ ({ color }: IAllWorkLinkProps) => color };
  font-weight: bold;
  line-height: 1;
  margin-top: 20px;
  transition: .5s;
  text-decoration: none;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    margin-top: 10px;
    font-size: ${ fontSize.px12 };
  }
  &:hover {
    opacity: .5;
  }
`

const FadeInWrapper = customFadeInWrapper({ delay: 500 })

interface IProps {
  theme: IThemeDetail
  works: IContentfulWork
}

const IndexWorkSection: React.FC<IProps> = ({ theme, works }) => (
  <Wrapper>
    <div>
      <SectionTitle color={ theme.color } index={ '01' }>WORK</SectionTitle>
      <AllWorkLink color={ theme.color } to={ '/work/' }> > ALL WORK </AllWorkLink>
    </div>
    <FadeInWrapper>
      <WorkThumbnail works={ works } theme={ theme } />
    </FadeInWrapper>
  </Wrapper>
)

export default IndexWorkSection