import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
// @ts-ignore
import ReactVivus from 'react-vivus';
// @ts-ignore
import svg from '../../../../static/index/sign-logo.svg';

const Wrapper = styled.div`
  width: 80%;
  margin-top: 50px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    margin-top: 20px;
  }
`

const SignLogo: React.FC = () => (
  <Wrapper>
    <ReactVivus
      id="foo"
      option={{
        file: svg,
        animTimingFunction: 'EASE',
        type: 'oneByOne',
        duration: 200,
      }}
    />
  </Wrapper>
)

export default SignLogo
